
const Transition = (transition, params) => {
    const source = pos => transition(pos, params)
    source.easeIn = source
    source.easeOut = pos => 1 - transition(1 - pos, params)
    source.easeInOut = pos => (pos <= 0.5 ? transition(2 * pos, params) : (2 - transition(2 * (1 - pos), params))) / 2

    return source
}

const createTransition = transition => {
    return new Transition(transition)
}

const Transitions = {
    Linear: zero => zero
}

Transitions.extend = transitions => {
    for (let transition in transitions) {
        Transitions[transition] = createTransition(transitions[transition])
    }
}

Transitions.extend({

    Pow: function(p, x){
        return Math.pow(p, x && x[0] || 6);
    },

    Expo: function(p){
        return Math.pow(2, 8 * (p - 1));
    },

    Circ: function(p){
        return 1 - Math.sin(Math.acos(p));
    },

    Sine: function(p){
        return 1 - Math.cos(p * Math.PI / 2);
    },

    Back: function(p, x){
        x = x && x[0] || 1.618;
        return Math.pow(p, 2) * ((x + 1) * p - x);
    },

    Bounce: function(p){
        var value;
        for (var a = 0, b = 1; 1; a += b, b /= 2){
            if (p >= (7 - 4 * a) / 11){
                value = b * b - Math.pow((11 - 6 * a - 11 * p) / 4, 2);
                break;
            }
        }
        return value;
    },

    Elastic: function(p, x){
        return Math.pow(2, 10 * --p) * Math.cos(20 * p * Math.PI * (x && x[0] || 1) / 3);
    }

});

['Quad', 'Cubic', 'Quart', 'Quint'].forEach((transition, i) => {
    Transitions[transition] = new Transition(p => Math.pow(p, i + 2))
});

export default Transitions